import React from "react";

// mã màu #ace543 : ghế được active
export const IconSeat1 = ({
  color = "#007FFF",
  width = "30px",
  height = "30px",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1"
    x="0px"
    y="0px"
    viewBox="0 0 97.8 93"
    enableBackground="new 0 0 97.8 93"
    style={{ maxHeight: width }}
  >
    <path
      fill={color === "#ace543" ? color : "#FFF"}
      stroke={color === "#ace543" ? "#cfcfcf" : color}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M76.7 82.8H15c-6.9 0-13-6-13-13V23.2c0-7.3 6-13 13-13h61.8c7.3 0 13 6 13 13v46.2c-.1 7.3-5.7 13.4-13.1 13.4z"
    />
    <path
      fill={color === "#ace543" ? color : "#FFF"}
      stroke={color === "#ace543" ? "#cfcfcf" : color}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M82.8 21H40.9c-4.3 0-7.8-3.9-7.8-8.2v-2.6c0-4.3 3.5-8.2 8.2-8.2h41.9c4.3 0 8.2 3.5 8.2 8.2v2.6c-.4 4.3-3.9 8.2-8.6 8.2zm0 70H40.9c-4.3 0-8.2-3.5-8.2-8.2v-2.6c0-4.3 3.5-8.2 8.2-8.2h41.9c4.3 0 8.2 3.5 8.2 8.2v2.6c0 4.7-3.5 8.2-8.2 8.2z"
    />
    <path
      fill={color === "#ace543" ? color : "#FFF"}
      stroke={color === "#ace543" ? "#cfcfcf" : color}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M89.7 77.6h-2.2c-3.5 0-6-2.6-6-6V23.2c0-3.5 2.6-6 6-6h2.2c3.5 0 6 2.6 6 6v48.4c.5 3.4-2.5 6-6 6z"
    />
  </svg>
);

export const IconSeat2 = ({ color = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.25 24.1">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect
          class="cls-1"
          fill={color}
          x="15.13"
          y="-14.03"
          width="23"
          height="52.15"
          rx="3"
          ry="3"
          transform="translate(14.58 38.68) rotate(-90)"
        />
        <path d="M49.7 24.1H3.55A3.55 3.55 0 0 1 0 20.55v-17A3.55 3.55 0 0 1 3.55 0H49.7a3.55 3.55 0 0 1 3.55 3.55v17a3.55 3.55 0 0 1-3.55 3.55zM3.55 1.1A2.45 2.45 0 0 0 1.1 3.55v17A2.45 2.45 0 0 0 3.55 23H49.7a2.45 2.45 0 0 0 2.45-2.45v-17A2.45 2.45 0 0 0 49.7 1.1z" />
        <rect
          class="cls-1"
          fill={color}
          x="37.04"
          y="8.88"
          width="13.98"
          height="6.34"
          rx="1"
          ry="1"
          transform="translate(31.98 56.08) rotate(-90)"
        />
        <path d="M46.2 19.59h-4.34A1.55 1.55 0 0 1 40.31 18V6a1.55 1.55 0 0 1 1.55-1.55h4.34A1.55 1.55 0 0 1 47.75 6v12a1.55 1.55 0 0 1-1.55 1.59zm-4.34-14a.45.45 0 0 0-.45.45V18a.45.45 0 0 0 .45.45h4.34a.45.45 0 0 0 .45-.45V6a.45.45 0 0 0-.45-.45z" />
      </g>
    </g>
  </svg>
);

export const IconSeat1Disabled = ({ width = "30px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1"
    x="0px"
    y="0px"
    viewBox="0 0 97.8 93"
    enableBackground="new 0 0 97.8 93"
    style={{ maxHeight: width }}
  >
    <path
      fill={"#A6A6A6"}
      stroke={"#B2B2B2"}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M76.7 82.8H15c-6.9 0-13-6-13-13V23.2c0-7.3 6-13 13-13h61.8c7.3 0 13 6 13 13v46.2c-.1 7.3-5.7 13.4-13.1 13.4z"
    />
    <path
      fill={"#A6A6A6"}
      stroke={"#B2B2B2"}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M82.8 21H40.9c-4.3 0-7.8-3.9-7.8-8.2v-2.6c0-4.3 3.5-8.2 8.2-8.2h41.9c4.3 0 8.2 3.5 8.2 8.2v2.6c-.4 4.3-3.9 8.2-8.6 8.2zm0 70H40.9c-4.3 0-8.2-3.5-8.2-8.2v-2.6c0-4.3 3.5-8.2 8.2-8.2h41.9c4.3 0 8.2 3.5 8.2 8.2v2.6c0 4.7-3.5 8.2-8.2 8.2z"
    />
    <path
      fill={"#A6A6A6"}
      stroke={"#B2B2B2"}
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M89.7 77.6h-2.2c-3.5 0-6-2.6-6-6V23.2c0-3.5 2.6-6 6-6h2.2c3.5 0 6 2.6 6 6v48.4c.5 3.4-2.5 6-6 6z"
    />
  </svg>
);
