import React, { useState } from "react";
import { Divider, Button, Tag, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCircle,
  faCar,
  faTag,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import moment from "moment";
import { convertMinutesToHoursAndMinutes } from "../../helpers/utils";
import _ from "lodash";

const CarItem = ({
  setOpenChooseCar,
  data,
  setCarSelected,
  addPrice = 0,
  area,
}) => {
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div
      className="mt-2 p-4 bg-white rounded-[12px] my-4 w-full"
      style={{
        boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      {data?.important_notification?.label ? (
        <Popover
          content={
            <pre
              onClick={hide}
              style={{
                lineHeight: 2,
                whiteSpace: "pre-line",
                fontSize: 10,
              }}
            >
              {data?.important_notification?.content}
            </pre>
          }
          title=""
          trigger="click"
          open={open}
          placement="bottom"
          onOpenChange={handleOpenChange}
        >
          <div className="flex items-center mb-2">
            <Tag
              icon={
                <FontAwesomeIcon
                  icon={faTag}
                  color="white"
                  className="w-[8px] mr-1"
                />
              }
              color="#01ad53"
              className="h-6 leading-3"
            >
              <span className="text-[10px]">Quy định</span>
            </Tag>
            <div className="underline text-[12px]">
              {data?.important_notification?.label}
            </div>
          </div>
        </Popover>
      ) : (
        <>
          {data?.notification?.label && (
            <Popover
              content={
                <pre
                  onClick={hide}
                  style={{
                    lineHeight: 2,
                    whiteSpace: "pre-line",
                    fontSize: 10,
                  }}
                >
                  {data?.notification?.content}
                </pre>
              }
              title=""
              trigger="click"
              open={open}
              placement="bottom"
              onOpenChange={handleOpenChange}
            >
              <div className="flex items-center mb-2">
                <Tag
                  icon={
                    <FontAwesomeIcon
                      icon={faTag}
                      color="white"
                      className="w-[8px] mr-1"
                    />
                  }
                  color={data?.notification?.id === 3 ? "#f6511d" : "#01ad53"}
                  className="h-6 leading-3"
                >
                  <span className="text-[10px]">
                    {" "}
                    {data?.notification?.id === 3 ? "Ưu đãi" : "Thông báo"}
                  </span>
                </Tag>
                <div className="underline text-[12px]">
                  {data?.notification?.label}
                </div>
              </div>
            </Popover>
          )}
        </>
      )}
      <div className="flex gap-4 mb-4">
        <img
          className="cursor-pointer w-[48px] h-[48px] rounded-lg object-cover"
          src={`${
            data?.company?.images[0]?.files["1000x600"]
              ? `https://${data?.company?.images[0]?.files["1000x600"]}`
              : `https://storage.googleapis.com/fe-production/icon_horizontal.svg`
          }`}
          alt="Hình ảnh"
        />

        <div className="flex flex-col flex-1">
          <div className="flex flex-wrap items-center gap-2 mb-1">
            <div className="font-semibold text-[14px]">
              {data?.company?.name}
            </div>
            <div className="flex items-center justify-between gap-2">
              <div className="flex gap-1 bg-[#01ad53] rounded px-1 items-center h-6">
                <FontAwesomeIcon
                  icon={faStar}
                  color="white"
                  className="w-[10px]"
                />
                <span className="text-white text-[12px]">
                  {" "}
                  {_.get(data, "company.ratings.overall", "updating")}
                </span>
              </div>
              <FontAwesomeIcon
                icon={faCircle}
                color="#484848"
                className="w-[4px]"
              />{" "}
              <div className="text-[12px] text-[#484848]">
                {_.get(data, "company.ratings.comments", "updating")} Đánh giá
              </div>
            </div>
          </div>
          <div className="text-[12px] text-[#767676] mt-1">
            {data?.route?.schedules[0]?.vehicle_type || ""}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#01ad53]">
          {moment(data?.route?.schedules[0]?.pickup_date).format(
            "HH:mm DD/MM/YYYY"
          )}
        </div>
        <div className="text-[#01ad53]">
          {convertMinutesToHoursAndMinutes(data?.route?.duration)}
        </div>
      </div>
      <div className="flex flex-col my-1">
        <div className="flex my-1 justify-between">
          <div className="text-[14px] flex text-left text-[#01ad53]">
            {data?.route?.pickup_points[0]?.name}
          </div>
          <FontAwesomeIcon
            icon={faArrowRight}
            color="#01ad53"
            className="mr-1"
            style={{
              fontSize: "14px",
            }}
          />
          <div className="text-[14px] text-right text-[#01ad53]">
            {data?.route?.dropoff_points[0]?.name}
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <div className="text-[14px] text-[#01ad53]">{area.from.name}</div>
        <div className="text-[14px] text-[#01ad53]">{area.to.name}</div>
      </div>

      <Divider
        variant="dashed"
        style={{ borderColor: "#01ad53" }}
        className="my-3"
        dashed
      />
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div>
            {data?.route?.schedules[0]?.fare.discount > 0 && (
              <span className="line-through text-[12px]">
                {data?.route?.schedules[0]?.fare.original.toLocaleString(
                  "vi-VN"
                )}{" "}
              </span>
            )}
            {data?.route?.schedules[0]?.fare.original !==
              data?.route?.schedules[0]?.fare.max && (
              <span className="text-[12px]">Từ</span>
            )}
            <span className="text-[18px] text-[#01ad53] font-semibold">
              {" "}
              {(
                (data?.route?.schedules[0]?.fare.discount > 0
                  ? data?.route?.schedules[0]?.fare.discount
                  : data?.route?.schedules[0]?.fare.original) + addPrice
              ).toLocaleString("vi-VN")}{" "}
              đ
            </span>
          </div>
          <div className="text-[12px]">
            {data?.route?.schedules[0]?.total_available_seats} Còn trống
          </div>
        </div>
        <Button
          variant="filled"
          color="#01AD53"
          onClick={() => {
            setOpenChooseCar(true);
            setCarSelected(data);
          }}
          className="bg-[#01ad53] text-white h-10"
        >
          <FontAwesomeIcon icon={faCar} color="white" />
          Chọn xe
        </Button>
      </div>
    </div>
  );
};

export default CarItem;
