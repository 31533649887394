import React, { useEffect, createContext } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import { Modal } from "antd";
import $axios from "./services/axios";

const ReachableContext = createContext(null);
const config = {
  title: "Token không hợp lệ!",
  maskClosable: false,
  footer: null,
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Có lỗi xảy ra vui lòng trở về trang chủ`}
      </ReachableContext.Consumer>
    </>
  ),
};

const App = () => {
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const location = useLocation();

  const decodeToken = async () => {
    // Lấy token từ URL
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (
        queryParams.get("verifyToken") ||
        localStorage.getItem("vexereToken")
      ) {
        const verifyToken = queryParams.get("verifyToken");
        if (verifyToken) {
          const res = await $axios.$post("/login", {
            VerifyToken: verifyToken,
          });
          localStorage.setItem("vexereToken", res.data.data.token);
          queryParams.delete("verifyToken");
          history.replace({
            pathname: location.pathname,
            search: queryParams.toString(),
          });
        }
      } else {
        modal.error(config);
      }
    } catch (error) {}
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <>
      <Switch>
        {/* <Redirect exact from="/" to={accessToken ? "/dashboard/home" : "/login"} /> */}
        {/* <RouteUnauthenticated path="/login" component={Login} /> */}
        {/* <RouteAuthenticated path="/dashboard/home" component={Home} /> */}
        <Redirect exact from="/" to={"/booking-ticket"} />
        <Route exact component={Home} path={"/booking-ticket"} />
        <Redirect to={"/booking-ticket"} />
      </Switch>
      {contextHolder}
    </>
  );
};

// const RouteAuthenticated = ({ component: Component, path }) => {1
//  const accessToken = Cookies.get("accessToken");
//   if (!accessToken) {
//     return <Redirect to="/login" />;
//   }
//   return (
//     <LayoutClient>
//       <Route exact component={Component} path={path} />
//     </LayoutClient>
//   )
// };

const RouteUnauthenticated = ({ component: Component, path }) => {
  // const accessToken = Cookies.get("accessToken");
  // if (accessToken) {
  //   return <Redirect to="/dashboard/home" />;
  // }

  return <Route exact component={Component} path={path} />;
};

export default App;
