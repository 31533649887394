import CryptoJS from "crypto-js";

export const generateSignature = (url, secretKey) => {
  const params = new URLSearchParams(url.split("?")[1]);
  const sortedParams = Array.from(params.entries())
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => value || ""); // Chỉ lấy giá trị, xử lý giá trị rỗng

  sortedParams.push(secretKey);

  // Tạo plaintext bằng cách nối các tham số
  const plaintext = sortedParams.join("|");

  // Tính toán chữ ký SHA-256
  const signature = CryptoJS.SHA256(plaintext).toString(CryptoJS.enc.Hex);

  return signature;
};

export const convertMinutesToHoursAndMinutes = (minutes) => {
  // const hours = Math.floor(minutes / 60);
  // const remainingMinutes = minutes % 60;
  // return `${hours}h${remainingMinutes}'`;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = "";
  if (hours > 0) {
    result += `${hours} tiếng`;
  }
  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} phút`;
  }

  return result.trim();
};
